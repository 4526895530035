// eslint-disable-next-line no-redeclare,no-unused-vars
function forEach(array, callback) {
  if (array)
    Object.entries(array).forEach(function (entry) {
      const [key, value] = entry;
      callback(key, value);
    });
}

// eslint-disable-next-line no-redeclare,no-unused-vars
function arraySearchAssoc(array, key, value, returnRow = false) {
  var result = false;

  if (isPlainObject(array))
    forEach(array, function (k, v) {
      if (v[key] == value) {
        result = k;
        return;
      }
    });
  else
    for (let i = 0; i < array.length; i++) {
      if (array[i][key] == value) {
        result = i;
        break;
      }
    }

  if (returnRow) return array[result];
  else return result;
}

// eslint-disable-next-line no-redeclare,no-unused-vars
function objectSearch(object, value) {
  if (!object || !value) return false;
  var i = Object.values(object).indexOf(value);
  return Object.keys(object)[i];
}

// eslint-disable-next-line no-redeclare,no-unused-vars
function arrayRemove(array, value) {
  return array.filter(function (ele) {
    return ele != value;
  });
}

// eslint-disable-next-line no-redeclare,no-unused-vars
function arrayEqual(a, b, sort = false) {
  var result = false;
  if (isEmptyObject(a) && isEmptyObject(b)) result = true;
  else if (sort && isArray(a) && isArray(b))
    result =
      JSON.stringify(Object.assign([], a).sort()) ==
      JSON.stringify(Object.assign([], b).sort());
  else result = JSON.stringify(a) == JSON.stringify(b);
  return result;
}

// eslint-disable-next-line no-redeclare,no-unused-vars
function arrayUnique(array) {
  return array.filter(function (value, index, self) {
    return self.indexOf(value) === index;
  });
}

// TODO: unused, remove?
// eslint-disable-next-line no-redeclare,no-unused-vars
function arrayOverlapping(array1, array2) {
  return array1.filter(function (n) {
    return array2.indexOf(n) !== -1;
  });
}

// eslint-disable-next-line no-redeclare,no-unused-vars
function arrayMerge(array1, array2) {
  if (!array1) array1 = [];
  if (!array2) array2 = [];
  if (!isArray(array1)) array1 = [array1];
  if (!isArray(array2)) array2 = [array2];
  return array1.concat(array2);
}

// eslint-disable-next-line no-redeclare,no-unused-vars
function inArray(value, array) {
  if (array && Array.isArray(array) && array.includes(value)) return true;
  else return false;
}

// sort 2 array
// eslint-disable-next-line no-redeclare,no-unused-vars
function sortArrays(array1, array2) {
  var helper1 = {};
  var counter = {};
  $.each(array1, function (i, val) {
    // double label
    if (isset(helper1[val])) {
      if (!counter[val]) counter[val] = 1;
      counter[val]++;
      val += ' ' + counter[val];
      array1[i] = val;
    }

    helper1[val] = i;
  });

  //	var helper2 = Object.values(array2); // not compatible
  var helper2 = Object.keys(array2).map(function (k) {
    return array2[k];
  });

  //	array1.sort();
  array1.sort(function (a, b) {
    if (a == null) a = '';
    if (b == null) b = '';
    return a.toLowerCase().localeCompare(b.toLowerCase());
  });
  $.each(array1, function (i, val) {
    array2[i] = helper2[helper1[val]];
  });
}
